import {useCallback, useEffect, useRef} from 'react';
import gsap from 'gsap';
import {cva} from 'class-variance-authority';

import useReducedMotion from '@/hooks/useReducedMotion';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import {type Mode} from '@/components/base/types';

interface BackgroundCornerBlocksProps {
  position:
    | 'top-right'
    | 'top-right-minimal'
    | 'bottom-left'
    | 'bottom-left-minimal';
  mode?: Mode;
  blockSize?: number;
  mobileBlockSize?: number;
}

export default function BackgroundCornerBlocks({
  position = 'top-right',
  mode = 'dark',
  blockSize = 70,
  mobileBlockSize = 50,
}: BackgroundCornerBlocksProps) {
  /*------------------
  Block Positioning
  ------------------*/
  const isMobile = useMediaQuery('(max-width: 768px)');

  const blockMainStyle = cva('absolute', {
    variants: {
      animated: {
        true: 'animated-block',
        false: '',
      },
      mode: {
        light: 'bg-black',
        dark: 'bg-dark-blue',
      },
    },
  });

  const generateBlockStyle = useCallback(
    (style: {
      width: number;
      height: number;
      right?: number;
      left?: number;
      top?: number;
      bottom?: number;
    }) => {
      const scale = isMobile ? mobileBlockSize : blockSize;

      const generatedStyle: {
        width: string;
        height: string;
        right?: string;
        left?: string;
        top?: string;
        bottom?: string;
      } = {
        width: `${style.width * scale}px`,
        height: `${style.height * scale}px`,
      };

      if (style.right !== null && style.right !== undefined) {
        generatedStyle['right'] = `${style.right * scale}px`;
      }
      if (style.left !== null && style.left !== undefined) {
        generatedStyle['left'] = `${style.left * scale}px`;
      }
      if (style.top !== null && style.top !== undefined) {
        generatedStyle['top'] = `${style.top * scale}px`;
      }
      if (style.bottom !== null && style.bottom !== undefined) {
        generatedStyle['bottom'] = `${style.bottom * scale}px`;
      }

      return generatedStyle;
    },
    [isMobile, blockSize, mobileBlockSize],
  );

  /*------------------
  Animation
  ------------------*/
  const containerRef = useRef<HTMLDivElement>(null);
  const reducedMotion = useReducedMotion(false);
  useEffect(() => {
    let gsapCtx = gsap.context(() => {
      // Set up a custom query selector so we can scope animations to this component.
      const gsapQuerySelector = gsap.utils.selector(containerRef);

      let blocksTl = gsap.timeline({
        paused: reducedMotion,
        repeat: -1,
        repeatDelay: 1,
        repeatRefresh: true,
        yoyo: false,
      });
      blocksTl.to(gsapQuerySelector('.animated-block'), {
        opacity: () => (gsap.utils.random(0, 1, 0.1) < 0.8 ? 0 : 1),
        duration: 0.1,
        stagger: {
          each: 0.1,
          from: 'random',
        },
      });
    }, containerRef);
    return () => gsapCtx.revert();
  }, [reducedMotion]);

  return (
    <div
      className="absolute top-0 left-0 w-full h-full pointer-events-none opacity-50"
      ref={containerRef}
    >
      {/* Top Right */}
      {position === 'top-right' && (
        <>
          {/* Layer 1 */}
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 0, top: 3})}
          ></div>
          {/* Layer 2 */}
          <div
            className={blockMainStyle({animated: true, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 1, top: 2})}
          ></div>
          {/* Layer 3 */}
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 0, top: 1})}
          ></div>
          <div
            className={blockMainStyle({animated: true, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 1, top: 1})}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 2, top: 1})}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 4, top: 1})}
          ></div>
          {/* Layer 4 */}
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 0, top: 0})}
          ></div>
          <div
            className={blockMainStyle({animated: true, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 1, top: 0})}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 2, top: 0})}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 3, top: 0})}
          ></div>
          <div
            className={blockMainStyle({animated: true, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 5, top: 0})}
          ></div>
        </>
      )}

      {/* Top Right Minimal */}
      {position === 'top-right-minimal' && (
        <>
          {/* Layer 1 */}
          <div
            className={blockMainStyle({animated: true, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 0, top: 1})}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 2, top: 1})}
          ></div>
          {/* Layer 2 */}
          <div
            className={blockMainStyle({animated: true, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 0, top: 0})}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 1, top: 0})}
          ></div>
          <div
            className={blockMainStyle({animated: true, mode})}
            style={generateBlockStyle({width: 1, height: 1, right: 3, top: 0})}
          ></div>
        </>
      )}

      {/* Bottom Left */}
      {position === 'bottom-left' && (
        <>
          {/* Layer 1 */}
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 0,
              bottom: 0,
            })}
          ></div>
          <div
            className={blockMainStyle({animated: true, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 1,
              bottom: 0,
            })}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 2,
              bottom: 0,
            })}
          ></div>
          <div
            className={blockMainStyle({animated: true, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 3,
              bottom: 0,
            })}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 5,
              bottom: 0,
            })}
          ></div>
          {/* Layer 2 */}
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 0,
              bottom: 1,
            })}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 1,
              bottom: 1,
            })}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 2,
              bottom: 1,
            })}
          ></div>
          <div
            className={blockMainStyle({animated: true, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 4,
              bottom: 1,
            })}
          ></div>
          {/* Layer 3 */}
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 1,
              bottom: 2,
            })}
          ></div>
          {/* Layer 4 */}
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 0,
              bottom: 3,
            })}
          ></div>
        </>
      )}

      {/* Bottom Left Minimal */}
      {position === 'bottom-left-minimal' && (
        <>
          {/* Layer 1 */}
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 0,
              bottom: 0,
            })}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 1,
              bottom: 0,
            })}
          ></div>
          <div
            className={blockMainStyle({animated: true, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 2,
              bottom: 0,
            })}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 3,
              bottom: 0,
            })}
          ></div>
          {/* Layer 2 */}
          <div
            className={blockMainStyle({animated: true, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 1,
              bottom: 1,
            })}
          ></div>
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 2,
              bottom: 1,
            })}
          ></div>
          {/* Layer 3 */}
          <div
            className={blockMainStyle({animated: false, mode})}
            style={generateBlockStyle({
              width: 1,
              height: 1,
              left: 0,
              bottom: 2,
            })}
          ></div>
        </>
      )}
    </div>
  );
}
